<template>
  <v-container fluid>
    <v-row no-gutters class="fill-height">
      <v-col cols="3">
        <v-card flat :height="headerCardHeight" tile>
          <v-card-title class="text-h4">
            Kiosk Analytics
            <v-icon large right color="primary lighten-3">
              mdi-google-analytics
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row justify="center" align="end">
              <v-col self-align="end">
                <v-select
                  v-model="selectedKiosk"
                  :rules="[(v) => !!v || 'Select a kiosk']"
                  :items="kioskList"
                  label="Select Kiosk"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card flat :height="headerCardHeight" tile>
          <v-card-text
            ><v-row>
              <v-col cols="4" align-self="center">
                <v-card width="95%">
                  <v-card-title class="justify-center">
                    <span
                      class="primary--text text--lighten-1 font-weight-regular"
                    >
                      Total User Sessions
                    </span>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <span
                      v-if="kioskAnalytics.totalUserSessions > 0"
                      class="text-h5 font-weight-bold grey--text text--darken-2"
                    >
                      {{ kioskAnalytics.totalUserSessions }}
                    </span>
                    <span
                      class="text-h5 font-weight-bold grey--text text--darken-2"
                      v-else
                    >
                      NA
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" align-self="center">
                <v-card width="95%" class="">
                  <v-card-title class="justify-center">
                    <span
                      class="primary--text text--lighten-1 font-weight-regular"
                    >
                      Total Questions Asked
                    </span>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <span
                      v-if="kioskAnalytics.totalQuestionsAsked > 0"
                      class="text-h5 font-weight-bold grey--text text--darken-2"
                    >
                      {{ kioskAnalytics.totalQuestionsAsked }}
                    </span>
                    <span
                      class="text-h5 font-weight-bold grey--text text--darken-2"
                      v-else
                    >
                      NA
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" align-self="center">
                <v-card width="95%" class="">
                  <v-card-title class="justify-center">
                    <span
                      class="primary--text text--lighten-1 font-weight-regular"
                    >
                      Overall Performance
                    </span>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center">
                    <span
                      class="text-h5 font-weight-bold grey--text text--darken-2"
                    >
                      NA
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>User Sessions</v-tab>
        <v-tab>Q&A Analytics</v-tab>
        <!-- <v-tab>Graphs</v-tab> -->
        <v-spacer></v-spacer>
        <v-btn
          @click="loadData(selectedKiosk)"
          class="ma-1"
          icon
          color="primary"
          dark
        >
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>
        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import reports from "@/components/dashboard/admin/kioskAnalytics/reports.vue";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    reports,
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5,
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
    ]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.qnaAnalytics.forEach((event) => {
        try {
          if (event.data().result.answers.length > 0) {
            interactions.push({
              kioskId: event.data().kioskID,
              result: event.data().result,
              timeStamp: event.data().timeStamp,
              transcript: event.data().transcript,
              id: event.id,
            });
          }
        } catch (err) {
          // console.error(err.message);
        }
      });
      return interactions;
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        let startTime = moment(event.data().userSessionStartTime.toDate());
        let endTime = moment(event.data().userSessionEndTime.toDate());
        sessions.push({
          id: event.id,
          kioskId: event.data().kioskID,
          userSessionDate: event.data().userSessionStartTime,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.data().userSessionStartTime,
          userSessionEndTime: event.data().userSessionEndTime,
          userSessionLogs: event.data().userSessionLogs,
        });
      });
      return sessions;
    },
  },
  watch: {
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5;
    },
  },
  methods: {
    loadData(kioskName) {
      this.loading = true;
      this.timeline = null;
      this.allRegisteredKiosks.forEach((kiosk) => {
        if (kiosk.data().name === kioskName) this.fetchAnalyticalData(kiosk.id);
      });
    },
    fetchAnalyticalData(kioskId) {
      this.$store.dispatch("getKioskAnalytics", kioskId).then(
        (response) => {
          console.log(response);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
  },
  mounted() {
    // this.$store.dispatch("getUserGroups").then(() => {
    //   console.log("Organization grps loaded");
    // });
    // if(this.allRegisteredKiosks.length === 0){
    //   this.$store.dispatch('getAllRegisteredKiosks')
    //     .then((response) => {
    //       console.log(response)
    //     }, error => {
    //       console.error(error);
    //     })
    // }
  },
};
</script>

